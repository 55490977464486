import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import "./App.css"; // Import your CSS file

const AnimatedParagraph = ({
  text,
  id,
  onClick = () => {},
  unredactedText = [],
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const [paragraphElement] = document.getElementsByName(
        `${id}-animated-paragraph`
      );
      if (paragraphElement) {
        const elementPosition = paragraphElement.getBoundingClientRect();
        if (elementPosition.top < window.innerHeight) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id]);

  const formattedText = () => {
    if (text.includes("<*")) {
      return (
        <p className={`text fadeInUp ${isVisible ? "visible" : ""}`}>
          {text.split(/(?<=[.!?])\s+/).map((sentence, index) => {
            if (sentence.includes("<*")) {
              let redacted = sentence.replace("<*", "");
              redacted = redacted.replace("*>", "") + " ";
              return (
                <span
                  key={`${index}${sentence.slice(0, 4)}`}
                  className={`${
                    unredactedText.includes(`${id}-text`)
                      ? "redacted animate"
                      : "redacted"
                  } ${id}-text`}
                  onClick={() => onClick(`${id}-text`)}
                >
                  {redacted}
                </span>
              );
            }
            return (
              <span key={`${index}${sentence.slice(0, 4)}`} className="">
                {sentence + " "}
              </span>
            );
          })}
        </p>
      );
    }
    return (
      <p className={`text fadeInUp ${isVisible ? "visible" : ""}`}>{text}</p>
    );
  };

  return <Element name={`${id}-animated-paragraph`}>{formattedText()}</Element>;
};

export default AnimatedParagraph;
