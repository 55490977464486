import "./App.css";

const Image = ({
  source = "https://www.rd.com/wp-content/uploads/2018/02/30_Adorable-Puppy-Pictures-that-Will-Make-You-Melt_124167640_YamabikaY.jpg?fit=700,467",
  caption,
}) => {
  return (
    <div>
      <img style={{ flex: "0 0 auto" }} src={source} alt="" />
      {caption && (
        <p className="caption" style={{ marginTop: 0, fontSize: 16 }}>
          {caption}
        </p>
      )}
    </div>
  );
};

export default Image;
