export const introTexts = [
  "I want to start by saying that I put this together out of love and respect for our relationship. While it has been a tough year for me and a difficult time understanding how we ended up here, I felt there was still something missing. I don’t know where you are now or how your life is evolving, and I’ve kept it that way to give you the space you need and to protect myself. I think about you and miss you every day.",
  "I may not make sense of what happened over the past year, but I’ll keep trying. In my largest project this year, I’ve spent many emotional hours recounting November 2022 - February 2023. I spent even more time backtracking the 4.5 years we spent together and what I can do better moving forward. Some conclusions I’ve reached are sad realities, and others remind me how connected I am to you. I cannot speak about how you felt, but I’ve processed what you’ve told me.",
  "Now, I’m sitting outside in Philadelphia, drinking a hot chai latte, and I get a little sad thinking about you sitting next to me, sharing your day, and smiling at me. I wake up wishing things could be different; that we could have talked in more depth at the end of November. I know I cannot change that.",
  "Still, I want you to know that I care about you, I often think about happy memories together, and I made this for you because I love you.",
];
export const content = [
  {
    texts: [
      "When I first met you, I remember a few details: your name stood out in the sign-ups for my dinner, you showed up late, and I was extremely curious to know more about you, but you ended up on the other side of the room. I had a case of love at first sight. I figured over the years that this wasn't an uncommon reaction to people meeting you. You had a light that you brought into the room.",
      "I was so impressed, but I thought you were out of my league. Actually, my mind couldn't even process that this was your first Shabbat dinner, and yet you were already so invested in Judaism. Despite being physically attracted to you, I didn't expect anything more than just getting to know you better.",
      "Looking back, I remember that dinner so poorly, but I remember you so well. If I had known what the next 4.5 years would look like, I would have told myself to do what I can to make you happy and keep you in my life. From the first time I saw you in my living room, to the last time we laid together on the carpet as you gathered your things to leave forever, I just wanted to keep you there and be next to you.",
      "While holding onto you wasn't a decision I could make alone, I desperately wish you had stayed. I wish we could have talked and seen the light through the obstacles.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1mQqUbm56gxhNHwo6CdJCIsh6i2fxKaUa",
    imageCaption: "First time I met you",
    position: 0,
  },
  {
    texts: [
      "We finally got to meet each other outside of the dinner. Honestly, I was feeling like you had some hesitation to see me again and our schedules not aligning made me think that our chemistry was off. This is the first picture I took of you during a fun day in the city. I was so happy that you played along with the idea of the shot. Suddenly, I felt so comfortable with you. I felt there was a side of you opening up and that there’d be more that we’d see eye-to-eye.",
      "I was still trying to figure you out, though. You talked to me about exploring Judaism and I was a little skeptical. I really just hadn’t met anyone who showed interest as you did. I immediately wanted to help you find what you needed to help you in any way I could. We ended up walking to Miznon where you let me “photoshop” you entry ticket to go to the Jewish mixer. I was afraid to scare you off, but wanted to show you my creative side. ",
      "One of my most vivid memories is of going to get some food at Miznon, and looking back to the seating area to see you looking at me. If there was a moment I started to really want you, it was then. I see your face in my memory looking at me, and I wish I could go back to that time and tell you about all the great things we’d do together; that we’d go through ups and downs, but in the end it would start from that moment that I’d commit myself to bringing you into my life.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1gdCJ6tdARHEvjAXdYcTE5cnvsgbPpiHo",
    imageCaption: "Our first time hanging out together",
    position: 1,
  },
  {
    texts: [
      "This is the first photo of us together. The night itself is a blur, but I remember you very clearly. I remember your facial expressions, your smile, your laugh, and just being generally easy-going with me. I had so much fun. I printed this photo for us a few years later. It meant so much to me because I was starting to see a layer of Fatimah that made me comfortable and seen.",
      "The other girl in the photo had looked me up on LinkedIn and was talking about how I teach code and was writing a book. I noticed how your eyes lit up. I was so happy to impress you in any way, and for years I continued to try to impress you and express my love for you in every aspect of my life. I wish I had said these things more to you and given you the satisfaction you were looking for throughout our relationship.",
      "That night, we had our first kiss. My mind was filled with ideas about how I could improve the experience and keep seeing you. I’ve looked at this photo many times over the past 5 years. It brings me back to a time that I hardly believe even happened. That one day transformed what you meant to me.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1LX18YfQS-csMEGhJj08xp3Lf5L3ADooO",
    imageCaption: "Our first photo together",
    position: 2,
  },
  {
    texts: [
      "That first night was memorable, but it’s also mostly a reminder of our roots in the relationship. We’d take any more goofy photos together. There’s a bit of each of us that likes to entertain. Like when we went to the improv classes together. I didn’t expect you to go on stage and do a funny accent, but you did it. You wanted my attention and you got it. You surprised me in many ways over the years.",
      "This night, you were both silly and elegant. I later discovered that you are these two things at your core. You care about your outward appearance but don’t want to compromise your happiness or have a genuinely fun time. We are both the same in this way. We’re both a little guarded, but pour our hearts out for the right person. In the meantime, we entertain, and we entertained each other to make each other laugh or pull ourselves out of a bad mood.",
      "I’m glad I captured these GIFs because a photo alone wouldn’t tell the story of that night. I can still remember your reaction after seeing these GIFs: eyes wide and a loud laugh (usually accompanied by a clap). You loved it, I loved it, and together, we built more memories than I can recall in one sitting. I’m grateful to at least have the photos to bring me back.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1QlXmloA3PLKEncq0fdymaNSfSSPuah_r",
    imageCaption: "Building chemistry early",
    position: 3,
  },
  {
    texts: [
      "One more photo: another GIF testing out interesting ideas to capture. You were so often on board to try new and creative photos, hobbies, and general activities together. These didn’t go unnoticed. How you took on learning the Santoor to match my musical side, how you took swimming lessons so we could eventually swim together (and how you let me teach you to swim a bit in Bali and Colombia), and how you cooked with me, took edibles together, painted, and made music. You inspired me as I had hoped to inspire you.",
      "I miss these times and I find it difficult to be the person I was in these photos with anyone else. Even though I look at this GIF and know that we’re just getting to know each other, I feel like we had already been dating for a year. I was so excited to show you off to my friends and introduce you to my life. Even having you meet my family early was important, though I know it wasn’t enough. I’m sorry for that, you deserved to be more involved.",
      "This is a side of you I think will continue to be a part of your personality. It’s a side I thought aligned with me better than anyone I’d been close with before, and a side I was looking forward to be an important part of how we communicated further in our lives together. It’s the body language, facial expressions, and wanting to be in a good mood together.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1Fycb8XK--HRJ5NiK49xGZ7Uwgv_IAlXh",
    imageCaption: "Understanding each other's goofiness",
    position: 4,
  },
  {
    texts: [
      "I took this photo right before going to Israel with my family. I was so excited to meet you and felt bad to go away for a month. I remember meeting you in Bryant Park and, for the first time, learning about your interest in yoga. I never liked to box you in as a certain type, because I never liked to do that for myself, but I saw how important it was for you to take care of your body and reflect through yoga, and I loved it too.",
      "Yoga was one of your activities that I really enjoyed doing with you. There are times I didn’t join you, and I’m not super flexible, but just as you tried to join me in my hobbies, I put a lot of effort to be there for you with yoga. When you told me about the yoga certification class I was so excited for you. Of course, I wanted you to do it. I wanted you to have and pursue what I would have wanted for myself. I really was happy for you, to see you push yourself in the yoga classes and take me along for the ride. ",
      "I wanted to see more of this I wish I could have been a better source of encouragement to bring out more of the best of you. It’s hard for me to walk into a yoga class these days without spending the whole session thinking about what you mean to me. I’ll always respect your commitment to yoga, and remember this day before a summer of missing you and he start of our relationship. ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1X-w6YvI7q8iz3C90GgnQEhRVAcylaoej",
    imageCaption: "Beginning of the summer; before I went to Israel",
    position: 5,
  },
  {
    texts: [
      "There’s not a lot to say about this photo in particular, other than it stood out to me. Your outfits, the way you took care of yourself and your looks, and the way you modeled for a photo. I was obsessed. This is one of a handful of photos that I’ve looked at a lot over the years. That’s how I saw you, physically: a model. You are gorgeous to me and through all the arguments, I never felt differently.",
      "With that said, I never needed you to wear makeup to appreciate you. I preferred your natural look, and just meshing together. We took so many “serious” face photos and then laughed about them together. I thought we had similar perspectives on what elevated us, while also not taking these things too seriously. I have so many photos, and still never felt that I had enough.",
      "I’ve never taken as many photos of someone as I have of you. I know that at times that bothered you, but I did it because I wanted to be able to look back on these days as our relationship grew to remind ourselves of where things started.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1HigJt-jSmeDq4jlCvrZRXx3cnuegvLKz",
    imageCaption: "You were a model to me",
    position: 6,
  },
  {
    texts: [
      "Same day, different angle. You’ve pointed at this photo before and commented on how you missed that time: when the relationship was new and we were obsessed with each other. You “loved” my short hair (and I really should have considered how growing my hair out didn’t really suit me, and that it was ok for you to not find it attractive and for me to adjust). This picture captures the early us.",
      "I look at this and I wish I could talk to you in person about it. I wish we could share our perspectives of that time and then lead into new recent reasons why we’d stay together. I thought there was enough in that foundation that showed our strengths to continue. But this is also when we started to see each other’s more challenging sides.",
      "If I could go back, I’d give myself a bit more patience and understanding. At the time I thought I knew what I wanted and that maybe the idea of us was too complicated. But that changed over time, and patience and communication improved. This is when I started to fall in love with you.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/15hcFrBJtRZhoE0zgz5Z4mI123lb8LkQE",
    imageCaption: "We loved the way we looked together",
    position: 7,
  },
  {
    texts: [
      "Another cool shot. This time, what stands out is the way you looked at me. With everything going on between us, I was probably most attached to this. It’s one of the things I miss the most. I’ve had dozens of dreams and flashbacks over the past months where you talk to me and look at me this way. I felt so connected and safe.",
      "I made mistakes in the relationship and feel that I’ve lost a lot as a result. Not seeing you like this is one of my greatest losses, and I’d sad thinking about the possibility of never sitting with you outside again while you look at me this way. These are the photos I shared with my closest friends to show them how I saw you — how sweet, calm, and present you were with me.",
      "",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1xFyQC1DnZ1kK7jEtpZRm6poXzGg-tjyl",
    imageCaption: "Admiring you in maroon",
    position: 8,
  },
  {
    texts: [
      "I can still feel what it was like preparing to out for dinner together. Getting ready and being out in the city was something I looked forward to. Maybe we didn’t go out to dinner as much as we’d like to. I was just so comfortable with you that I just wanted to end up laying down next to each other at the end of the day. The feeling I get when I see this GIF is the smile on your face, trying to do the tongue pattern, but also thinking about how it will turn out.",
      "I felt strength and comfort here. It was probably a good time to introduce you to my family and share the feelings I had with them. This was a side of the relationship people didn’t get to see or experience. It was the part that was most intimate to me and a part I miss and think about often.",
      "",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1g91pxJTRisIKGBlc38Yf-JAy1cv2xNvx",
    imageCaption: "Learning to be silly together",
    position: 9,
  },
  {
    texts: [
      "Meeting at my dinner was particularly special to me. I loved preparing Shabbat, and now, my memories of those dinners are tied to you. I miss the days of preparing together, hosting together, and the memorable nights. From the start, I was so happy to find someone who wanted to plan with me, host with me, cook with me, and be my partner at the dinner. These dinners gave me the most confidence about eventually starting a family together, and what the origins of Shabbat would look like for us.",
      "I loved to see people react to your Kushari and how you always were right on cue to prep and bring out tea to the group. I was glad that you loved to make others happy the way I did in this way. I wish I had done more for your 30th birthday. Now I have so many great ideas, but at the time it felt like my best offering was to host a dinner in your honor, to cook your Qatari foods, and celebrate you. I really wanted to make you happy, and I should have woken harder to read you better during times when you weren’t happy.",
      "Our last dinner together was still a great memory for me, though I wish it wasn’t tied to the beginning of the end for us. I remember you wearing your fuzzy sweater and having my arm around you, and just being happy that you were there next to me. Not being able to prepare dinner with you is a sad thought. I miss it so much. It’s really become tough for me to do it again without thinking of you. I think about you every day, but on Friday nights it sometimes hurts the most.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1twKS27CVUsyBQ5vHajJySAJox1RJ7Fbr",
    imageCaption: "Hosting shabbat together",
    position: 10,
  },
  {
    texts: [
      "In our first year together, I put a lot of thought into what was most similar between us and what was missing. At first, I wasn’t sure how creative you liked to get, but slowly I saw that side come out. You love to have fun and will put your heart into making an experience memorable and creative. I didn’t care so much about Halloween before meeting you, but I wanted to be silly and creative with you during this time. I saw the opportunity to experience something new and unique together, and that’s why I pushed for it.    ",
      "My mistake is that I was too caught up with the activities, and I should have checked in with you more. I knew what I loved, and there was a part of me that could get frustrated if you didn’t show interest in the same things. It wasn’t my smartest trait. You were, and are, so special to me because of the effort you put in, and the interest you showed in doing new things together. I wanted a lifetime of experiencing new and fun things together with you. I struggle to see it another way.    ",
      "One of my favorite things about you is how thoughtful you were and attentive to my interests. From the Durian desserts to the ice cream museum birthday drinks to the Wrecking Room to smash electronics together during my bad days, you were there with a surprise that you thought of. I was in love. I took some of that for granted yes, but understand that I did deeply love you. I didn’t want to travel or explore with anyone else.     ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/10LybXcAa59s9RUJaPUL7IZ00H0_4A1pu",
    imageCaption: "Halloween",
    position: 11,
  },
  {
    texts: [
      "You were my girlfriend, my partner, and truly my best friend. I was most vulnerable with you, and still, I should have been more vulnerable. I desperately wanted to make things work. I think you know that. From the apps I built for us, to the ideas to get past the petty fights. I know how I would do things differently now. I had to grow, and I think you grew too. I was stupid for not listening to you about moving our relationship forward. Maybe I was too afraid of something getting in the way and tearing us apart. I didn’t want to lose you, and yet, I knew we had some obstacles around getting to that next stage.    ",
      "New perspectives have shaped me recently, but they’ve only strengthened how I feel and what I miss. I am so grateful to have spent so much quality time with you. I’m sorry for letting the good times get overshadowed by the distance that grew between us. I tried my best at the time to preserve our relationship in the way I understood it. And while I’m hurt, you are not suddenly a stranger to me. You are still very much a part of me and how I live.     ",
      "One year you bought the relationship scratch-off book. I was so excited to try this with you. I wish we continued to use it because I saw it encouraging us to go to more dinners and plan more activities. I noticed your investment in the relationship, and those were the times I told myself “She’s the one”. I felt your love. I saw it In your face. I wish I returned the love back to you in a way you could feel appreciated    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1cWu-DxSI7-4bBuXgRQVF-HeY4Ee3HPJ5",
    imageCaption: "Going around the city and enjoying each other's company",
    position: 12,
  },
  {
    texts: [
      "When I pass Stuytown I have so many memories go through my mind. From spending time with Snowball, to cooking with you and the smell of your incense in the morning. 17 Stuyvesant Oval is where we first kissed, <*it’s where we first had sex*> and it’s where I place you in my mind when I think about us together. This is where I got to know you and got to see you live your lifestyle. This is where you took care of me when I was sick and where I comforted you during tough times.    ",
      "You embraced me and invested in me. You made your kitchen kosher and set up your apartment to be a comfortable space for me. You were so thoughtful and caring, and I won’t forget it. We laughed and cried here. We watched so many shows and movies, from The Good Place, to Game of Thrones and last kingdom, and Girlfriends and the Circle. I still remember the first time you watched The Circle and laughed so hard. I wish I could watch those videos still without feeling sad that you’re not here.    ",
      "This is where we made chai together, where you endlessly said فى’عينك to me and taught me other phrases in Arabic. I felt like you could connect with my Sephardic background, and close the cultural gaps between us. I was so excited to visit Qatar with you, to see where you grew up, and to meet your family. Now, I have a hard time going to your old neighborhood or remembering why you taught me t'henen wyhad kh'lish without the nostalgia making me miss you. I still see you getting ready in the morning in your cute outfits and looking at me this way, smiling and saying “What happened last night?” And we both laugh together.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1wcO8Y1AXlA7ZuLfShgf3nPSNQ5NDYpzt",
    imageCaption: "This is how I saw you when we got ready to go somewhere",
    position: 13,
  },
  {
    texts: [
      "The more time I spent with you, the more I wanted to look good, so together we could make the rest of the city jealous. I took these photos with you and it made me so happy to be with someone I was so attracted to and with whom I had such good chemistry. But the experiences we had is really what tied me to you.     ",
      "I remember the time you went to New Haven and I took a train to meet you there. We met at Asally’s pizza and I brought edibles I bought in Colorado. I had tried edibles twice before when offered by a friend from college, but never purposefully took one with someone I actually cared about. It was your first time, so I wanted you to feel safe. We both took a piece of chocolate. We walked back to your hotel in the cold. Then it started to hit us. We both got silly, the word “chicken” makes you laugh uncontrollably, and we just lose ourselves in the night and connect on a different level.     ",
      "<*We have some of the most passionate sex we’ve had together up to that point.*> The night was special and I was, again, so happy you were willing to try this experience together. Then, it opened the door for more nights like this. We got high during Shabbat dinners and nights out, at the Coldplay concert, and many more. I felt safe with you, and hoped that I provided the same for you. I miss those days, and I miss my closest partner being there to give me her hand, a hug, and look me in the eyes at the end of the night.      ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/18D9OSt3QbBfyS2gbYnAZv_K5SjWspfe7",
    imageCaption: "Looking good together",
    position: 14,
  },
  {
    texts: [
      "Nearly every day for us was a mini journey. From finding new cafes to going to freebie events, or shopping together; it was effortless and loving. I remember the good times over the rough times. Even when tension built, it feels like we had long stretches of days of pure happiness. We had a long list of places to travel together: Morocco, Ethiopia, New Zealand, Iceland, Mexico, and more. We were just so hungry to experience more together.    ",
      "We started our own mini book club together (It’s here that we picked out the book in the Amazon bookstore.) We learned to cook together, Nepalese food, and during the class in Bali. We just both had a passion to learn and, together, grow in ways we could best relate to one another. I loved you more after these experiences, and I loved the love you showed toward me. The way you squeezed me, hugged me and held me gently.    ",
      "I wanted to be in your life as much as I could, I didn’t see another way I said things I didn’t mean, at times that I lost patience, but I tried to show the positive side as I better developed those skills. I felt there was much more to salvage between us and I think that pictures like these could have reminded us of that before it was too late.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1h4JniZRkzn_IK-lbhrEJZuxzETdBemjX",
    imageCaption: "Loving each other",
    position: 15,
  },
  {
    texts: [
      "We were both kids. We were silly. I loved to see you being silly. While I was working hard to make a name for myself professionally and work hard, I didn’t want to take life too seriously. You were similar in that way. Some days you were the smart, intellectual, and driven Fatimah who made sure to complete her yoga routine and get some research done. On other days you were my silly alien (“I’m not weird”). We both let our weird out and had good control over it.    ",
      "This was always one of my favorite pictures of you. You were just being playful with me and I adored it. You were so cute. Yes, I took many photos of you that you didn’t like. I never meant to capture a side of you that I thought was ugly or that you might think was ugly. They were all beautiful to me. Yes, I know, cliche. But really. I was obsessed with you and loved to capture these innocent moments; times when we didn’t need to dress up to impress anyone else, and could just be silly and ourselves together. Photos like this made me feel warm inside.    ",
      "We made fun of each other. We poked each other. I wish I took more of your jokes better and appreciated your versions of making fun of me. In the end, I was hoping that these playful moments would be an overall positive experience that would bond us even closer. You embraced the Qatari face, and I loved that you did. You know that humor is one of my strongest personality traits, and I worked overtime with you to bring out the best moments to make you laugh. I loved to watch you laugh uncontrollably. It always made me happy.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1BBjlhDhyMb3NeycX6FLjSCWz2S3M2GfQ",
    imageCaption: "Some of your silliest moments were my favorite",
    position: 16,
  },
  {
    texts: [
      "When you entered my life, I had a new routine of getting hot drinks together. I’m sure this is something that’s not unique to us being together, but it’s an association I strongly connect to waking up in the morning with you. I know you need your coffee, and these days I’ve been drinking more. It’s rare that I get a chai latte or cappuccino and not think of you. I miss the days of going to work in a cafe together. The days of getting up and finding a new cafe to bike to. I miss the way you took care of me and brought me a coffee.    ",
      "You are in your zen space when you have your coffee or rose/turmeric latte and do yoga. It seems we’d hardly ever fight or argue when in this relaxed space. It’s an aspect of my day that I feel is missing. I also don’t want to replace it with another person. This was a part of your personality and how we interacted. It’s not the drink itself, but the way we started our day together. The way you balanced grinding coffee beans with my morning meetings. How you put a fresh cup next to my laptop while I worked. I know you took care of me, and I should have shown greater appreciation for it.    ",
      "I was so excited to go to coffee plantations with you when we got the chance. The more I got to learn about the more I wanted to explore the world of those activities with you. I wanted to be there when you found a new coffee shop you liked and I wanted to be there when you had your best coffee. I want you to be happy in this way. It’s difficult for me to know that this may be shared more intimately with another person. For me, the memory is pure and the experience is close to my heart.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1p7mRfxTDKH_rHBsgUg3njsWKohsi4Gof",
    imageCaption: "I took so many photos because I loved to look at you",
    position: 17,
  },
  {
    texts: [
      "Remember this night? You when to the Philosophy at Midnight event at the Brooklyn library. You told me to join you. I forget where I was, but I was on the fence about going. In the end, I told myself that I want to spend time with you that night. I didn’t want to miss something you were excited about. So, I made my way to Brooklyn and found you. You explored some of the rooms at the event and found a corner and laid on the floor. We took this photo. You were so happy to see me there, and I felt so warm to be with you.    ",
      "It was a cold night that ran late. I’m glad we made it work. If I could have these times back I would maximize your happiness and explore further than we even discussed. I’m satisfied with the number of activities we did together, and mostly I was just happy to be with you at the end of the day. Though, I would have taken these days back and better connected with what you wanted. I wanted to serve you and I wish I could have served you better.    ",
      "This was a cozy picture of a time I remember well in my mind. These are the moments I felt we could look back at after a few years and smile at each other, proudly because we found each other and made it work.     ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1-Ge05b2QaLBtYpsEKBh8lDNdLcoHooOc",
    imageCaption: "Meeting you late at night",
    position: 18,
  },
  {
    texts: [
      "When I first visited your apartment and saw you had two cats I was happy to see that we had more in common. I also had my two cats in Philly and was excited to get to know yours better. Cats ended up being present throughout our relationship, through the high and the low points. When you lost Princess, I was so sad with you. That night I felt like I lost my own cat, and I felt the sadness that took over you. I wanted to be there with you every second and get closer to you. It was a tough time and I was mad at myself for not doing more.     ",
      "Getting to spend so much time with Snowball was such a bonus for me. I am lucky that you are a cat person, and that you took such loving care of your cats. I felt like this would be an easy transition into a lifestyle together. One of my many mistakes was staying in my apartment with a roommate and Rosie, when I should have put more attention to living with you and helping you raise Snowball. It’s really something I wanted, but I waited too long.    ",
      "I also wish you got to meet my cats. We talked so much about them and I really wanted you to see them. I’m sad that you never got that chance. I was just so sure that we’d be together for the long run and that there would be an opportunity to see them more often. I look at photos and videos of Snowball and I’m sad to be so distant from him and you now. I feel like I lost a great deal with the two of you and I do miss the good days and jokes that we had together.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1tn_xXAsTFzRe78LrBVXK5SByn8NTQts6",
    imageCaption: "Our love for cats",
    position: 19,
  },
  {
    texts: [
      "This was such a special day for me. Whenever you came with me to Philly, I was so thankful you were willing to take a train and spend time with me there. I also felt guilty about not introducing you to my family. My sense of time was off and I regret it. Still, my memory of this day is so strong and I don’t think I’ve been to a better concert experience than going with you to the Guster show. I was happy to introduce this band to you, and happy that you enjoyed the music like I did.",
      "The night was magical, but mostly because my best friend, partner, and the person I loved most was there with me. You made my night special. I remember leaving the show that night feeling so much more connected to you. I would do anything for you. The woman I loved was joining me in something I loved and she loved it. Like always, I wanted to make a memory of the night with you. I wish I had done more: bought matching shirts, taken you out to a fancy dinner.",
      "I know better now. You knew better then. Without hesitation, you agreed to come with me. It’s not the first or last time you encouraged us both to make the effort and do something special together. I wish I had not declined any of your suggestions. I wanted the same, and still want it. Seeing these shows together linked my love of playing music and singing with the person I thought of when I played. I wanted you to be my audience, because I was really just singing love songs to you.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1U7TN98k_kvDLpGsfidMhLeM00kIQ67jE",
    imageCaption: "Guster",
    position: 20,
  },
  {
    texts: [
      "Obviously, there’s a lot to be said about biking together, but I just want to highlight how we made certain activities our own. When there was a chance to take an adventure, I knew you were ready to do it together; to compete together. You used to tell me “You play too many games”, but really we both liked to play. We both wanted to prove ourselves to the world and to each other. You proved a lot to me. From the biking events to the mini 5k to supporting me at the triathlon in Hawaii, I felt your love and support and competitive nature.    ",
      "Our mutual competitiveness brought us closer together, even though at times it made situations tense. I thought it was one of your most attractive qualities. I loved the idea that we could be a team and take on the world. We talked about being a power couple, I think because we had so much confidence in our chemistry and abilities. We understood each other’s strengths and weaknesses. Watching reality tv game shows made us want to compete together. Most importantly, we wanted to do these things to experience more challenging situations that would make us stronger.      ",
      "That’s why I rushed to the first opportunity to bike with you, climb with you, and get on a plane together. You weren’t a weenie and you showed me that where trust was lacking in other aspects of our relationship, it was strong here. We could lift each other up and work as a team. I realize too late that we should have used this strength to realign and recalibrate ourselves. It’s a bond that I cherish and one that I miss.      ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1Wnqc2BPtPL4isaz-JYTvhceR0ZcB9NgQ",
    imageCaption: "Biking together",
    position: 21,
  },
  {
    texts: [
      "When you joined me in San Diego it was the first time we traveled together this far within the US, and the first time we were attending a wedding together. You were so supportive of me and easygoing with my friends. I didn’t hesitate to show you off, because you were a special gem that attracted so much attention on your own. Like the other times, I wanted to build a special memory with you in San Diego. We had some of the best tacos together, watched the seals on the shore, went paragliding	over the cliffs, and danced together all night for the first time.    ",
      "We got high at the pre-wedding party the night before. We laughed so hard and ate a large bowl of strawberries meant for a party of 50 people. It was really an experience and the best strawberries we’ve ever had. We were sidekicks to each other and were spontaneous together, and I loved it.      ",
      "I was so drawn to you that night. I wanted to just leave the wedding and spend the rest of our time together, alone. <*We snuck off to the wedding bathroom and had sex, which I felt was a bit risky, but so exciting*>. You got your hair done and dressed up so nicely, I almost didn’t recognize you. We also opened up to each other a bit more that weekend. I wish we just sat down and let everything out on the table. If I could do it again, I would make it so clear to you that I want nothing to get in the way of us.      ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1qhy_QeqQq4Y4TXS8isQgnm5r2Qhp3k7b",
    imageCaption: "Dressing up and supporting each other",
    position: 22,
  },
  {
    texts: [
      "As much as I love biking, I loved it more together. I dreamed of traveling the world and biking side-by-side, stopping for fresh fruit, and forgetting about any other stress in our lives. This is another area in which I was so impressed by you. You jumped right into biking with me, doing the 5 boro bike tour, and even buying your own bike. I was so happy on this day. I felt your commitment and I was so proud of you. I became even prouder to see you bike with me longer distances and bike to campsites.    ",
      "You wanted to show your strengths to me, and I may have not seen a greater example of that than through your commitment to bike together. Your athleticism and enthusiasm for working outmatched my own. We hiked so much together, in every country we visited. We took boxing classes together, rock climbing, kraal maga, running sessions, and of course, class passes and Barry’s. We did so much, and I always felt like we were just getting started.       ",
      "Elements of being active together made me feel more and more like I could never let you go. We were in sync. There are times I pushed you too hard. I’m sorry for that. I wasn’t right. Maybe I needed a little more growth to fully appreciate you in this way. I wish these opportunities to share together	still exist somewhere in our lives, and I’ll continue to hope for that.      ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1MzcslGw_i9N34FS08t85s_5RdTMEHlSc",
    imageCaption: "Your first bike in NYC",
    position: 23,
  },
  {
    texts: [
      "You were my concert buddy. There wasn’t really an opportunity you turned down. From Guster to Coldplay, to Alicia Keys, and Rolling Stones, you were on board. You enhanced my experience by being there with me. You were the energy I was looking for in a partner to enjoy life together.    ",
      "My family is the same, and some of these concerts were my way of including you in the routines I grew up with. I emphasized experiencing them with you, not only because I had fun, but because it’s something that was a part of my identity. This is also why I might have been stressed before or during concerts, or emotional. Though, it was a gift for me in the end. Having you there with me, to smile wide and sing together. You showed me that you were invested here, and like everything else, I wish it could continue.     ",
      "",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1XvxxJW0QiBp1u2-FlS-f932klXH9d9tT",
    imageCaption: "Going to concerts together",
    position: 24,
  },
  {
    texts: [
      "Turkey was a bit of a spontaneous trip for us. I know I was asking a lot at the time for you to travel (for the first time) with me internationally for a friend’s wedding. But I mostly wanted to use the opportunity to travel with you. You knew by this point how important traveling the world was to me in exploring and understanding life better. This was a bit of a test to see how well we’d travel together, and it’s one of my most memorable times with you. From preparing for the flight, and the lounges, to you trying to grab the ice cream from the ice cream vendor (this was so funny) in Istanbul, it started off so great.",
      "We continued to be spontaneous, booking the flight to Capadocia, taking the cave hotel, flying in the hot air balloon, renting the moped, going to the winery we found, and archeological sites. The trust you had in me to drive the motorbike was just what I wanted to see from you, and I wouldn’t have traded those moments to be with anyone else. The hammams, the couples massage.<* We had, to my memory, one of our most intimate sexual nights in Turkey, really crossing into new comfort zones with each other*>. I felt so connected to you. I wanted to keep you forever. I felt so in sync with you. I liked what you liked and you had fun wherever we were both together.",
      "My friend’s wedding was one of the first times I seriously thought about potentially marrying you. I looked at you many times that night and thought about how we might one day have a beautiful wedding. I wish I could have held onto that feeling and shared it in a stronger way with you. I was so shy and in my own head to let you know and have that conversation. The video of my friend throwing the bouquet and you being relieved that you didn’t catch it is one of my favorite videos of you. I’ve watched it many times. Though, I wish you caught it.     ",
      "That wedding night you were focused on making me happy. You went to get late-night sardines with me and pushed us to take a 4 am taxi to take another spontaneous 6 am flight back to Istanbul. I was happy you suggested that. That’s how we got to go to the Bazar and I bought my gold bowl and teapots.<* We also tried to go to the hammam where we had sex one last time in the changing room before leaving the country*>. It was all so fast, so hot, so meaningful, and special to me.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1vqH6pY1e_5efGCumqY0qYJn6jHyRY2Jg",
    imageCaption: "Turkey",
    position: 25,
  },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1lhGc6ZTcheYDkAc5qAwFNSl3_M-hQyq4",
  //   imageCaption: "Love watching you pose",
  //   position: 26,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1TvJ7NsZF6SuCi1Z1hD4e7QL3U3ovAN2c",
  //   imageCaption: "What I saw every day (But I loved it)",
  //   position: 27,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1BAEKreRCIQBrv4GFCWRHhxDvYQAS8B1S",
  //   imageCaption: "You committing to the role",
  //   position: 28,
  // },
  {
    texts: [
      "Early in our relationship, I mentioned how much time I spent in Asia and how I wanted to travel there with you. I knew this would be a stretch goal, but I wanted it to happen. And it did. The moment we booked Bali was another moment that I could look at you and envision a future together aligning on great adventures. From the Hong Kong lounge, where we got our first taste of Asia, really good food, and a shower break, to landing in Bali and hitting the road immediately, I didn’t want to be there with anyone else.    ",
      "I was nervous that you wouldn’t trust me to drive the scooter, but you had no hesitation. I was in heaven with you. We stopped for Durian, hiked, and explored a new world together. I wish it could have lasted longer and that we’d have more opportunities to travel and improve our experience from this big trip. You had the great idea to go to the 5-star hotel in the middle of the island. What an incredible experience. Pushing through the harshest rain storm I’ve ridden through, and making it to this incredible hotel with you was a story not many couples get to tell. We swam together, <*enjoyed the outdoor shower*>, and got our first taste of a coffee plantation. We picked strawberries and went to that amazing gourmet dessert experience. Each day we eased into this routine and I wanted to maximize the days and cherish them for longer than we had.    ",
      "You were a great travel partner and you toughed through some of my bullshit. You knew how to make great suggestions for us both, while also not neglecting my interests. As the days of the trip wound down, I knew I had longer to spend in Indonesia, but in my gut, I was sad to see you go. I wanted you to expend the trip and be there with me. I should have done more to make you comfortable and helped pay for Snowball to get the support and care he needed so you could commit to spending more time with me. I was a bit selfish, and I would do it over again with more consideration for your emotional energy. Thank you for this experience.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1SrnLZ8E857KyWNf6XxzcWTCrmywT_iVR",
    imageCaption: "Bali",
    position: 29,
  },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1cIgKxYmwAWyFF3npCiehOjZcgvh9SBZw",
  //   imageCaption: "Treating ourselves",
  //   position: 30,
  // },
  {
    texts: [
      "Growing up I went camping during the summer and I went to overnight camp where I got to be in the woods. I loved it so much that I wanted my future partner to share that experience with me. When you and I got the opportunity to camp in Vermont and upstate NY, I was excited to recreate that experience with you. I was so happy to see how much you enjoyed it. It made me proud to know I’ve found someone so open-minded to sleep outdoors, and moreover, someone who enjoyed it as much as I did.     ",
      "We made camping a special ritual. Getting up in the morning, getting coffee and bagels, and taking the train to the last stop. This isn’t something I had with anyone else before. We created this for ourselves. Our friends were shocked and impressed that we carried our gear and biked on our own. What did we get out of it? Another special connection, beautiful weather, incredible hikes, and cooking over the fire. I admit I took it too far at times and pushed you to your limits, and I’m sorry for that.    ",
      "Seeing you celebrate two birthdays while camping felt like a special win for me. I thought this might be a great new tradition for us. That my next birthday we’d do the same and camp in another country together. I really enjoyed sitting in nature with you, picking up fruit, vegetables, and fish, and curating a new menu each time. We scored amazing sunsets, sweet cherries, and clear skies for stars. I remember telling you at the time just how special the moment was; that we wouldn’t always get to go camping, let alone bike to our campsite, but there we were, doing it. Prepping our bikes in anticipation of camping the next day was a feeling I hope to never forget. These times were special for me, and I think even more special for you. I won’t take the memory for granted.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1wCL1nEGvCmTzr53PmrGM1o9XK8W-LYZw",
    imageCaption: "Adventuring outdoors",
    position: 31,
  },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/13wr6HnsRmrAhV00chtXhFAK9LxP2PF7j",
  //   imageCaption: "Pushing ourselves physically to get to the top",
  //   position: 32,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/18P1ku59u6ZBhqC0VgcTVBb7GX146ZQVx",
  //   imageCaption: "Qatari face",
  //   position: 33,
  // },
  {
    texts: [
      "One last look at you and what your child-like nature meant to me. You were my inspiration, for my music and my humor. You were my audience, and I was yours. I wanted to see you smile like this, and no matter how embarrassing the moments, photos, or videos, what I saw, in the end, was someone I truly respected and who emulated what I would do. You are a strong and determined person. You deserve what you set your mind to. At your core, you are also a fun and silly girl.    ",
      "I hope you continue to find happiness in expressing yourself in goofy ways. I don’t have wisdom or advice, other than I know how much happiness this brought the two of us. It was my number one goal to make you smile and laugh if nothing else. You did the same for me. I wish my family got to see this side of you. You were always worried that my family wouldn’t accept you, but really, you were more like me and my family than you realize. It all felt so aligned and perfect, and it was my mistake for not introducing you.    ",
      "I may not get to talk to you or see you, maybe again in my life. I hope that’s not the case. There’s no one else I can share these moments with; no one else who with laugh and cry with me the way we did. This wasn’t a typical relationship for me. I was steadily better understanding you and evaluating life together. I wanted this. I got it for the moment, and I’m sad to see you go.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1ANIAK02TbX29hy66cgEZa-jM3P4Oi9p9",
    imageCaption: "Being silly",
    position: 34,
  },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1g3e8dJDKfCjqdTELKB4xzFpEOqkR2Jul",
  //   imageCaption: "A deeper love was there",
  //   position: 35,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1SkDGJIvkpIaB6ggXRf9r95f3xENS5YHJ",
  //   imageCaption: "Exploring the city together",
  //   position: 36,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1lB_yAGhqvXkyv815oiHHU1ieVUxpKqcS",
  //   imageCaption: "Celebrating your birthdays",
  //   position: 37,
  // },
  {
    texts: [
      "Celebrating your birthday was a challenge for me. I wanted to deliver an experience that would captivate you. I know I could have and should have done more. I was so happy, though, that we made it to Puerto Rico (your recommendation). You knew how to enjoy and treat yourself. I should have treated you more than you had already expected from me. I should thank you for taking me on your adventure here.     ",
      "We did so many activities this weekend. From restaurants to hiking, and the cave adventure. I remember when you forgo to clip your carabineer on the edge of the cliff of the cave. That was a scary moment. You cried, and while we laughed about it later, I was scared for you. I didn’t want you to get hurt or upset. When the Colombia lounge incident happened. I rushed to you and did what I could to make sure you were ok, as best I could. I gave you the whole row of the plane to rest while I sat on the floor and went with you to the hospital. I felt so bad for you and felt responsible. I didn’t want more physical and emotional damage to mark our relationship.    ",
      "I put into the relationship what I thought was most meaningful to me. I should have been a better listener to give you what you wanted. I understand my weaknesses better now and how I could have made you happier. Still, I look at the picture of us in the cave, and I think “We did that!”. We actually went the distance, side by side, to take on challenges, physical and emotional. This was my testament to the strength we still had together.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1h5eRtc36tLLdml7RkhcFQ9yfygV9OQ02",
    imageCaption: "Going deep to experiencing the best together",
    position: 38,
  },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1-X3mqUTc65ti12k5A8vuZKrxCqQyP1kh",
  //   imageCaption: "Dressing up and supporting you",
  //   position: 39,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1omJB1JJV8DbMBAnqNxDA04nvLWXAStip",
  //   imageCaption: "As much as we could fit in our schedules",
  //   position: 40,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1PyrBWuWaAOk_rEsujSXxkAcYsLz7MXiY",
  //   imageCaption: "Being partners",
  //   position: 41,
  // },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1oUdWXTD4lOREgj7x23ZTm4wQ29cWRu3U",
  //   imageCaption: "We both loved adventure (this, thanks to you)",
  //   position: 42,
  // },
  {
    texts: [
      "On our last major trip, you asked if we could go to the top of the tallest mountain in the world and see the stars. The day before my big race, for which I’d been training for months, I was hesitant, but couldn’t miss the opportunity with you. I’d been there before, and just like the Volcanos National Park, I wanted to experience it again with you. I enjoyed it much more. I was there with someone I cared about. I wanted to, and did, drive around the whole big Island, not because I needed to see it a second time, but because I needed to take this opportunity to grow our relationship, and because I wanted to be there with you.    ",
      "We almost didn’t make it, but ultimately got a ride to the top. We had incredible weather, and as we took photos, I took a moment to appreciate you. I thought to myself how special it was to be in that spot with you, above the clouds. Was this just another trip? No, it was a unique moment that I knew I’d look back on and appreciate your company and partnership. I should have put aside the pettiness that had come a few days earlier, apologized, and told you what you meant to me at that moment. I had said some mean things and I regret how it made you feel.    ",
      "I was so fortunate to have such an adventurous girlfriend who pushed me to take on a challenge, at even inconvenient times. We were spontaneous and we cashed in on those experiences. I was constantly reminded of our compatibility and was upset with myself for overshadowing it with my cold and distant behavior. I did need a break, but I didn’t want to lose you. I wanted to recalibrate and find a way forward; to find a way to get back to these experiences, where we hold each other, hug, and kiss, and thank God that we found each other. You were my blessing and with each day that goes by I feel less blessed to not have you in my life.    ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1dWbwEzGTj4ZLi-rf3dhMTR00sbaPjs3B",
    imageCaption: "Exploring the highest peaks together",
    position: 43,
  },
  {
    texts: [
      "From early in the relationship, I understood how close you are to your mom. Someone with family values was an important trait I looked for in my partner, and I saw that in the way you kept in touch with your mom and brother, and how you worked so hard to make your mom happy on her trips to NYC. I wanted to be a part of that. I also wanted to get to know her because I was serious about you. I knew that it would matter to me to know who your mom was before taking major steps forward in the relationship. And in my way, I suggested the museum guide day.    ",
      "I was actually surprised that you agreed to it, though you were very nervous. That day was amazing. I put so much of myself into it because I wanted to impress your mom and get to know her at the same time. It wasn’t a game — I felt like that experience could really influence our future to an extent. I was happy you see it go well. I think you were also surprised that your mom had so much patience for the tour and was so involved in talking about the art. As we walked up the stairs in the museum behind your mom, you reached out and held my hand. I won’t forget that. I asked your mom how she felt about you marrying someone from another culture and she said she is open to it, and only wants you to be happy. Then we sat and talked about our backgrounds (I still have that audio), where I shared that my grandparents were Iraqi. I felt bonded and closer to you than before.    ",
      "Each time your mom visited I wanted another opportunity to meet her, another chance to get to know her and you, and, in my mind, see her accept me. The photography session also went well. This time I got to really see you and your mom enjoy the day together, real candid moments, that I hadn’t seen before. I got to document it and also give your mom a special experience in the city. I was sure this would be the last time I saw your mom as a random photographer, and that the next time I saw her I’d be introducing myself as a future son-in-law. I got used to the idea of being a part of your family. I spent a lot of time working out the details in my head.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1YbH3EuUff2G8-ZE8_g1_jSMCDWCD6qmx",
    imageCaption: "I loved to see you and your mom together",
    position: 44,
  },
  {
    texts: [
      "From the first day I met you, you were studying at the New School. I don’t know a Fatimah that isn’t in school or finishing her Ph.D. I imagine that’s possibly changed by now or that some aspects of your life and studies have new updates. I just want to say that I was really looking forward to the day to celebrate with you as Dr. Al-Khaldi. I had been there for the journey, and just like the time I spent writing my book, I understood the effort you put in and the pressure you felt to complete your dissertation. Like other aspects about you, I was impressed and proud.    ",
      "The moments that I could be there for you, I tried to be there. I wanted to support you, champion your studies, and be at the finish line with you like you were for me. I miss laughing with you about baby bonds and some of the drama in your office. I wish I could have been a better sounding board for your dissertation ideas, though I tried to help understand your statistics and give you the resources that might help. I really wanted to be closer to you in this aspect of our lives.      ",
      "The truth is, I should have tried harder. I should have been more involved. I wanted to, and I regret not putting time aside to really invest in what defined your career. I want to say that I am still proud of you and want to see you succeed, and I think I will see that.       ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1A8QHOOaANidvRW89AYrtaSbCEkUbQ1Or",
    imageCaption: "Bring there for your accomplishments",
    position: 45,
  },
  // {
  //   texts: ["", "", ""],
  //   type: "story",
  //   image:
  //     "https://lh3.googleusercontent.com/u/0/d/1609nI9ovZjMyLnXtbSeQLHgamSvJRiFS",
  //   // "https://lh3.googleusercontent.com/u/0/d/1Mns_z-zW2dFzVd5-jt8xPPyDIZwGDaJK",
  //   imageCaption: "Camping",
  //   position: 46,
  // },
  {
    texts: [
      "You sent me this photo in google photos a few months ago. I look terrible in it, but I love this photo still. I see the love in your eyes. The effort you put into celebrating my birthday was so special to me. I really enjoyed that day, but mainly because you organized it. It was only a few months before the breakup. How could it have been so close to the end? The hugs I got from you were hugs that I could take for the rest of my life.    ",
      "You were my beautiful princess, and I didn’t show you that enough. I didn’t appreciate you in the way you needed. But I did appreciate you deep inside. Your face in this photo is candid. I like to think that it’s really how you felt. And despite the arguments, we could always get back to this place. That what I did with my life, my status, or my friends would make no difference in the love we shared. This was my happy place and I would trade a lot to have it back    ",
      "",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1TceX98ebu6W6HSXpiLv2A2AUKW-twzDq",
    imageCaption: "I felt your love",
    position: 46,
  },
  {
    texts: [
      `This is one of our last photos together. It was the end of November, just before the breakup. While I don’t know what thoughts are going through your mind, I’m enjoying your company and we have a nice night out together. I’m grateful to have you in my life. We have cocktails, and for the moment, we only see each other. We had just gone to a Friday Night Hub event a week earlier (November 4th) and walked back to my place together, and you, in your green dress, tell me "Jon, you know I love you." I stay quiet. I joke and tell you that you’re just tipsy. And then you turn your body to me, make eye contact, and say something that I’ll vividly remember for a long time: "Wallah, Jon, I am crazy about you. I really love you and I want this to work." You’re a little teary, and I tell you that I know.    `,
      "With less than a month left in our relationship, I reached a new level of love with you. I felt a new type of love that had me thinking that I need to make it work; that I need to find a new job and change my pace to get us to the next level. I really thought this and believed it. You standing there and telling me all this struck me in a way that made me want to push away any distractions and focus only on you. I should have. The timing of these experiences sometimes drives me crazy. What happened? What did I do? Was it the layoff? Was it something else that triggered the sudden disconnect?    ",
      "Two weeks after this photo we go back to Friday Night Hub, but we go separately. You are upset with me that day, and I am not sure exactly why. I should have dropped what I was doing and went directly to you and hugged you. That day changed my life forever, and I regret not acting differently. The struggle begins, and while we get back together after a couple of days, the damage had already begun. By the following Friday, we’re happy again and plan Shabbat together, but I make the mistake of going to Philly and leaving you in NYC alone, in what would turn out to be a night that continues to haunt me. I think about these weeks a lot. I don’t want to influence your decisions, but I do wish I was thinking more clearly to be there with you.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1Ct3w5HK9JZ68XHlHdc-rPtXewZvCQM4Y",
    imageCaption: "Some of our last moments",
    position: 47,
  },
  {
    texts: [
      "This shot is the last time we watched The Last Kingdom. Now it feels so long ago that we cared about a show together. That we held each other to a standard of watching certain shows with each other so we could be better aligned and process a story with one another. The show itself was not the point; it was the experience of watching it with you, making it our own.      ",
      "I would have turned down the pressure of how we watched this show and others. I wanted it to be a pleasant experience and not one that would drive you away. I still haven’t watched the Last Kingdom movie because it makes me think of you; of what we had together. I wish you would connect with me again like this. Smiling on your bed at me and imagining a life together; where we would joke under the sheets and end the night on a good note.      ",
      "It was stupid of me to not notice you more. <*I am stupid for not initiating sex with you every day*>. I wanted to and I wanted our relationship to grow. I couldn’t imagine then, and I can’t believe it now, just how much fell apart in such a short time. I miss these times every day and every time I turn on the tv      ",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/1xy_yH3lp2AYi9xKUBRZcLNqqGR0S0WU4",
    imageCaption: "Our routines",
    position: 48,
  },
  {
    texts: [
      "This is the toughest part for me to write. I added this because I need to acknowledge where we’ve ended up now and where we left off. I know you tried to keep in touch, and that made it harder for me to distance myself and stop talking. I desperately wanted to convince you to change your mind, but I also know that trying to do that might result in you not feeling like you made your own decision, or even driving you further away. From mid-December onwards I wanted to give you the space you asked for. I was just so hurt, and in such a bad place, but I tried to stay patient.    ",
      "The 4.5 years we spent together were 4.5 years of bonding, not only being general partners. I feel like I know you so well, which is why it pains me so much to see our relationship break apart so quickly and to see you move on. To be honest, I am still confused, and it may never make sense to me. But we are both living our lives and I can’t hold your decisions against you. If this is the path you want to explore, it’s the way it is. I wish I could change whatever specific things I did to drive you away. I have a few in mind, and I know that I won’t let my mistakes ever get in the way of a relationship I want to keep.    ",
      "The thing is, with all the pain I’ve felt, I still wanted to make you happy and keep you in my life. No choice I’ve made since December has felt like the right choice. I’m writing this because it feels like something I should do, something I should get off my chest, and maybe one of the only ways I can express to you what you mean to me. I think you know me really well, too. When times got ugly and you got mad at me, I waited for the tension to ease up so we could push away the delusions and resentment we might have built and reconnect with each other the way we fell in love with each other.     ",
      "This picture is of the last time you were in my room working with me on ways to potentially save our relationship. I was ready to make the sacrifices you wanted. I was stubborn because there was a lot on my mind and I had a hard time making sense of my uncle passing away and my layoff from Facebook. The timing wasn’t great, but then there was never a good time to go through this. This photo was the last time I held my breath in hopes that a switch would go off for you and you’d turn to me and say “Let’s try again.” You fell asleep in my bed that night talking about how easy it was to talk with one another, how we laugh and have the best jokes, and generally how happy we make each other. But you said you just weren’t sure about what to do.      ",
      "Maybe it was really the feeling of uncertainty, or maybe it was because you felt like you found something better. I just want to remind you that my love for you is strong and to see you leave is possibly the hardest thing I’ve ever experienced. In January, I told you that I was ready to set up a proposal and marriage timeline with you. I meant it. Yes, I wanted you back with me, but I wasn’t trying to waste time with you. I wanted to be with you for the rest of my life. You said you wanted that too. Scrambling to figure out how to make this clear to you, I talked with you each night, sang songs to you, and reminded you of what we had together. I was crushed. I’m still crushed. Maybe by that point, you were already looking forward to starting this new relationship you’re in. The timing was not in my favor, I know.      ",
      "Many months have passed, and while I’m not going to chase after you in a way that you may find uncomfortable, I am going to tell you, again, what’s at stake for me. Eight months later, and you’re still the one I love. I am hurt, but I am patient and forgiving. I’m forgiving of the distance time created between us and of my missed cues to save the relationship sooner. When you came to my apartment during one of your last times and packed your things, I vacuumed the carpet next to you. I laid you down gently next to me. I wanted to pause the chaos. I just wanted to have a simple moment with you. Not to touch you, or manipulate you. Just to see you in front of me. To soak in the view I’d adored for so long, and that I’d soon miss for months or years to come. I’ve had many dreams, real dreams, about that moment. You, laying in front of me, tearing up and looking into my eyes. I held your hand and tried to comfort you and tell you that I just don’t want to cause you any pain.       ",
      "In my dream, we lay there forever, and just talk, and share stories, and jokes. You laugh your big laugh and look at me like you know I’m up to something funny. You hold my hand and squeeze it tighter while I tell you a sad personal story. You put your hand on my thigh and squeeze lightly to remind me that you are really there. We cry and talk about the pain we caused each other. We talk about therapy and the apps I built for you. We talk about the fights and laugh at them. We laugh more when we think about scenes from reality tv, last kingdom, Shabbat dinners, and bird watching in Bali. It’s all so raw, and feels so real. It feels like something we’ve done all the time with each other and something we’d continue to do each night before we fall asleep. You flick your finger in excitement when I talk about Knafe, Kushari, our grilled cheese, homemade pasta, Patrizia’s mushroom pizza, Emmett’s, Vatan, Kiki’s, and so many more. From the creative dishes we made together, to the ones we explored together, we remained on the carpet talking about them.",
      "As we inch closer to each other I know there’s a border, a limit that I cannot cross. I’m just there to appreciate you. My heart races because I love you so much, and you’re so close, but I can’t reach you somehow. I cry and tell you that we can make it work. We can move in together, we can build trust, and be open to a level we’ve never before experienced; that we can spend the money and live a great life. I tell you that I love you, that I love you so much, and that I’m stupid for not expressing it sooner to you. Then I wake up, and you’re not there. My heart is still beating hard from the dream, and the tears are real.      ",
      "I miss you. I don’t know where to go from here. This is what I know, and so I am sharing it with you: my best friend. I hope it reaches you and that there’s something within you that understands where my message is coming from. I wrote it with all my energy and I remain hopeful. I continue to root for your success and happiness and will be there for you when you need me.",
    ],
    type: "story",
    image:
      "https://lh3.googleusercontent.com/u/0/d/10IxJc0_6NRHkMNalcoGUPbT1Zna0F8JP",
    imageCaption: "A last attempt",
    position: 49,
  },
];

export const imageBaseURL = "https://lh3.googleusercontent.com/u/0/d/";

export const imageUrls = [
  "18D9OSt3QbBfyS2gbYnAZv_K5SjWspfe7",
  "1X-w6YvI7q8iz3C90GgnQEhRVAcylaoej",
  "1omJB1JJV8DbMBAnqNxDA04nvLWXAStip",
  "1YbH3EuUff2G8-ZE8_g1_jSMCDWCD6qmx",
  "1HigJt-jSmeDq4jlCvrZRXx3cnuegvLKz",
  "1xFyQC1DnZ1kK7jEtpZRm6poXzGg-tjyl",
  "1g91pxJTRisIKGBlc38Yf-JAy1cv2xNvx",
  "1twKS27CVUsyBQ5vHajJySAJox1RJ7Fbr",
  "1XvxxJW0QiBp1u2-FlS-f932klXH9d9tT",
  "10LybXcAa59s9RUJaPUL7IZ00H0_4A1pu",
  "1cWu-DxSI7-4bBuXgRQVF-HeY4Ee3HPJ5",
  "1oUdWXTD4lOREgj7x23ZTm4wQ29cWRu3U",
  "1wcO8Y1AXlA7ZuLfShgf3nPSNQ5NDYpzt",
  "1g3e8dJDKfCjqdTELKB4xzFpEOqkR2Jul",
  "1ANIAK02TbX29hy66cgEZa-jM3P4Oi9p9",
  "1SkDGJIvkpIaB6ggXRf9r95f3xENS5YHJ",
  "1h4JniZRkzn_IK-lbhrEJZuxzETdBemjX",
  "1BBjlhDhyMb3NeycX6FLjSCWz2S3M2GfQ",
  "1vqH6pY1e_5efGCumqY0qYJn6jHyRY2Jg",
  "1cIgKxYmwAWyFF3npCiehOjZcgvh9SBZw",
  "1dWbwEzGTj4ZLi-rf3dhMTR00sbaPjs3B",
  "1lhGc6ZTcheYDkAc5qAwFNSl3_M-hQyq4",
  "1lB_yAGhqvXkyv815oiHHU1ieVUxpKqcS",
  "1h5eRtc36tLLdml7RkhcFQ9yfygV9OQ02",
  "1-X3mqUTc65ti12k5A8vuZKrxCqQyP1kh",
  "1A8QHOOaANidvRW89AYrtaSbCEkUbQ1Or",
  "1p7mRfxTDKH_rHBsgUg3njsWKohsi4Gof",
  "1tn_xXAsTFzRe78LrBVXK5SByn8NTQts6",
  "1Mns_z-zW2dFzVd5-jt8xPPyDIZwGDaJK",
  "1mQqUbm56gxhNHwo6CdJCIsh6i2fxKaUa",
  "1Ct3w5HK9JZ68XHlHdc-rPtXewZvCQM4Y",
  "1xy_yH3lp2AYi9xKUBRZcLNqqGR0S0WU4",
  "1-Ge05b2QaLBtYpsEKBh8lDNdLcoHooOc",
  "1gdCJ6tdARHEvjAXdYcTE5cnvsgbPpiHo",
  "1QlXmloA3PLKEncq0fdymaNSfSSPuah_r",
  "1Fycb8XK--HRJ5NiK49xGZ7Uwgv_IAlXh",
  "1LX18YfQS-csMEGhJj08xp3Lf5L3ADooO",
  "1wCL1nEGvCmTzr53PmrGM1o9XK8W-LYZw",
  "13wr6HnsRmrAhV00chtXhFAK9LxP2PF7j",
  "1TvJ7NsZF6SuCi1Z1hD4e7QL3U3ovAN2c",
  "1U7TN98k_kvDLpGsfidMhLeM00kIQ67jE",
  "1BAEKreRCIQBrv4GFCWRHhxDvYQAS8B1S",
  "1Wnqc2BPtPL4isaz-JYTvhceR0ZcB9NgQ",
  "18P1ku59u6ZBhqC0VgcTVBb7GX146ZQVx",
  "15hcFrBJtRZhoE0zgz5Z4mI123lb8LkQE",
  "1qhy_QeqQq4Y4TXS8isQgnm5r2Qhp3k7b",
  "1SrnLZ8E857KyWNf6XxzcWTCrmywT_iVR",
  "1PyrBWuWaAOk_rEsujSXxkAcYsLz7MXiY",
  "1TceX98ebu6W6HSXpiLv2A2AUKW-twzDq",
  "1MzcslGw_i9N34FS08t85s_5RdTMEHlSc",
];
