import "./App.css";
import Section from "./Section";
import ReactGA from "react-ga";
import Image from "./Image";
import AnimatedParagraph from "./AnimatedParagraph";
import { content, introTexts } from "./data.js";
import React, { useEffect, useState } from "react";

ReactGA.initialize("UA-42079839-1");

function App() {
  // const highlightedTextRef = useRef("");
  // const [highlights, setHighlights] = useState(() => {});
  const [unredactedText, setUnredactedText] = useState(() => []);
  const [passcode, setPasscode] = useState("");
  const [isGranted, setIsGranted] = useState(false);
  const [processedContents, setProcessedContents] = useState([]);

  const orderContent = () => {
    return content.sort((a, b) => a.position - b.position);
  };

  useEffect(() => {
    setProcessedContents(orderContent());
  }, [processedContents]);

  // const makeURLSafe = (stringToEncrypt = "hello") => {
  //   const base64String = btoa(stringToEncrypt);
  //   return base64String
  //     .replace(/\+/g, "-")
  //     .replace(/\//g, "_")
  //     .replace(/=+$/, "");
  // };

  // const getURLvalue = (urlSafeString) => {
  //   const base64String = urlSafeString.replace(/-/g, "+").replace(/_/g, "/");
  //   return atob(base64String);
  // };

  // const handleSelection = (contentIndex, textIndex) => {
  //   return;
  //   const selection = window.getSelection();
  //   const highlightedText = selection.toString();
  //   if (highlightedText.trim() === "") return;
  //   highlightedTextRef.current = highlightedText;
  //   const additonalHighlight = {
  //     contentIndex,
  //     textIndex,
  //     h: highlightedTextRef.current,
  //   };
  //   highlights[contentIndex][textIndex] = [
  //     ...highlights[contentIndex][textIndex],
  //     additonalHighlight,
  //   ] || [additonalHighlight];

  //   setHighlights(highlights);
  //   console.log(highlights);
  // };

  // const scrollRef = useRef(null);
  // const subComponentRefs = useRef([]);

  // useEffect(() => {
  //   subComponentRefs.current = subComponentRefs.current.slice(
  //     0,
  //     content.length
  //   );
  // }, [content]);

  // const scrollToNext = () => {
  //   console.log("HERE", scrollRef.current);
  //   const currentRef = subComponentRefs.current.find((ref) => ref.current);
  //   const currentIndex = subComponentRefs.current.indexOf(currentRef);
  //   if (
  //     currentIndex !== -1 &&
  //     currentIndex + 1 < subComponentRefs.current.length
  //   ) {
  //     subComponentRefs.current[currentIndex + 1].current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // };

  // const changePasscode = (event) => {
  //   const text = event.target.value;
  //   setPasscode(text);
  // };

  const correctPass = () => {
    return btoa(passcode.toLowerCase()) === "cWF0YXJhaXJ3YXlz";
  };

  // const formatHighlightText = (text, contentIndex, textIndex) => {
  //   const currentHighlights = highlights[contentIndex][textIndex];
  //   if (currentHighlights) {
  //     for (let h of currentHighlights) {
  //       if (text.includes(h) && h !== "") {
  //         const start = text.indexOf(h);
  //         const subsection = <span className="highlighted">{h}</span>;
  //         const value =
  //           text.slice(0, start) + subsection + text.slice(start + h.length);
  //         return value;
  //       }
  //     }
  //   }
  //   return text;
  // };

  const encoded = (text) => {
    return isGranted ? text : btoa(encodeURIComponent(text));
  };

  const handleTextClick = (classId) => {
    if (!unredactedText.includes(classId)) {
      setUnredactedText([...unredactedText, classId]);
    }
  };

  useEffect(() => {
    if (btoa(passcode.toLowerCase()) === "cWF0YXJhaXJ3YXlz") {
      trackPass();
      setTimeout(() => {
        setIsGranted(true);
      }, 1500);
    }
    if (btoa(passcode.toLowerCase()) === "bmV3eW9yaw==") {
      if (window.clicky) {
        window.clicky.log("/Jon", "Jon Granted");
      }
      setTimeout(() => {
        setIsGranted(true);
      }, 1500);
    }
  }, [passcode]);

  const trackPass = () => {
    if (window.clicky) {
      window.clicky.log("/", "Passcode Granted");
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>A Letter to you</h1>
        {!isGranted && (
          <input
            className={`magic-input ${
              isGranted || correctPass() ? "animate" : ""
            }`}
            value={passcode}
            placeholder="Enter code here"
            onChange={(e) => setPasscode(e.target.value)}
          />
        )}
        <h1 className="arabic">فتوم</h1>
      </header>

      <div
        style={{
          minHeight: 300,
          paddingLeft: 42,
          paddingRight: 42,
        }}
      >
        <h2 style={{ display: "flex" }}>Fatimah</h2>
        {introTexts.map((text, index) => {
          return (
            <AnimatedParagraph
              key={index}
              text={encoded(text)}
            ></AnimatedParagraph>
          );
        })}
        <h2 style={{ display: "flex", justifyContent: "flex-end" }}>Jon</h2>
        <AnimatedParagraph
          key="legend"
          id="legend"
          onClick={handleTextClick}
          unredactedText={unredactedText}
          text={encoded(
            "There's some sensitive text you may not want to read. <*It looks like this*>. But you may choose to click it to reveal and read it. No one else has read this."
          )}
        ></AnimatedParagraph>
      </div>

      <div className="container" style={{ padding: 40 }}>
        {/* <button
          onClick={() => {}}
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          Scroll to Next
        </button> */}
        {processedContents.map((item, contentIndex) => {
          return (
            <Section key={contentIndex}>
              <div
                style={
                  {
                    // display: "flex",
                    // alignItems: "flex-start",
                    // flexWrap: "wrap",
                    // flexDirection: "row",
                    // alignContent: "center",
                    // justifyContent: "space-between",
                  }
                }
              >
                <div
                  style={{
                    minWidth: 270,
                    width: "35%",
                    minHeight: 300,
                    padding: 12,
                    paddingRight: 48,
                    float: "left",
                  }}
                >
                  {/* <h2>{item.position}</h2> */}
                  <Image
                    source={
                      isGranted
                        ? item.image
                        : "https://www.industrialempathy.com/img/blurry.svg"
                    }
                    caption={encoded(item.imageCaption)}
                  />
                </div>
                <div
                  style={{
                    padding: 12,
                  }}
                >
                  {item.texts.map((text, textIndex) => {
                    return (
                      <AnimatedParagraph
                        key={textIndex}
                        text={encoded(text)}
                        onClick={handleTextClick}
                        unredactedText={unredactedText}
                        id={`${contentIndex}-${textIndex}`}
                      />
                      // <p
                      //   key={textIndex}
                      //   style={{ flex: "1 1 auto" }}
                      //   className="text"
                      //   onMouseUp={() =>
                      //     handleSelection(contentIndex, textIndex)
                      //   }
                      //   // style={{}}
                      // >
                      //   {/* {formatHighlightText(contentIndex, textIndex, text)} */}
                      //   {text}
                      // </p>
                    );
                  })}
                  <div style={{ clear: "both" }}></div>
                </div>
              </div>
            </Section>
          );
        })}
      </div>
    </div>
  );
}

export default App;
